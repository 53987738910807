import { useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';

export const useDebouncedUpdate = <T>(state: T, handleUpdate: () => Promise<void>, delay = 500) => {
  const handleUpdateRef = useRef(handleUpdate);

  useEffect(() => {
    handleUpdateRef.current = handleUpdate;
  }, [handleUpdate]);

  const debouncedUpdate = useRef(
    debounce(() => {
      handleUpdateRef.current();
    }, delay),
  );

  useEffect(() => {
    debouncedUpdate.current();

    return () => {
      debouncedUpdate.current.cancel();
    };
  }, [state]);
};
