export enum EntityType {
  RELATED_PARTY = 'RELATED_PARTY',
  SIGNING = 'SIGNING',
}

export type SubmissionStatus = SubmissionRelatedPartyStatus | SubmissionSigningStatus;

export enum SubmissionRelatedPartyStatus {
  CREATED = 'CREATED',
  SUBMITTED = 'SUBMITTED',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
}

export enum SubmissionSigningStatus {
  /**
   * Send to worker for document preparation
   */
  PREPARING = 'PREPARING',
  /**
   * Successfully merged the files amd valid signers, ready to submit to signing provider.
   * Can't change the documents and signers anymore
   */
  READY_TO_SEND = 'READY_TO_SEND',
  /**
   * Sent to signing provider, waiting for signers to sign
   */
  SENT = 'SENT',

  // Below are the final statuses

  /**
   * Successfully signed by all signers
   */
  SUCCESS = 'SUCCESS',
  /**
   * Rejected by the signers
   */
  REJECT = 'REJECT',
  /**
   * Error occurred during the signing process
   */
  ERROR = 'ERROR',
  /**
   * Not signed
   */
  NOT_SIGNED = 'NOT_SIGNED',
}

export interface Submission<T extends SubmissionStatus> {
  status: T;
  updatedAt?: Date;
}
