import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import Card from '../../../../../components/card/Card';
import { JobDocumentsWidget } from '../JobDocumentsWidget/JobDocumentsWidget';
import { Button as ButtonCustom } from 'libs/ui/atoms/src';

import { DocumentStatus, DocumentType, Job, JobDocument, JobStatus } from 'api';
import { useJobForm } from './useJobForm';
import { useDebounce } from 'hooks/useDebounce';
import { WaitPleasePopup } from 'components/waitPleasePopup/WaitPleasePopup';
import { useTypedSelector } from 'store';
import { selectJobDocuments } from 'store/job';
import {
  DocumentsValidationErrorTypeEnum,
  ModalDocumentsValidationError,
} from '../JobDocumentsWidget/DocumentValidationModals';
import { useDidUpdateEffect } from 'hooks/useDidUpdateEffect';
import { useNavigate } from 'react-router-dom';
import { JobProblemsAccordion } from 'components/jobProblems/JobProblemsAccordion';
import { JobErrorModal } from './modals/JobErrorModal';
import { ProblemsNavigationModal } from './modals/ProblemsNavigationModal';
import { JobMainInfoWidget } from '../../components/job-main-info';
import { ErrorModal } from '../../../../../libs/ui/atoms/src/lib/modal/ErrorModal';
import { SwitchInputField } from '../../../../../libs/ui/atoms/src/lib/switch/SwitchInputField';

type RequirementType = '1' | '1+' | '0' | '0+';

type RequiredDocuments = {
  [key in DocumentType]?: RequirementType;
};

const RequiredDocumentsByTypeRustica: RequiredDocuments = {
  [DocumentType.CERTIDAO_PERMANENTE]: '1',
  [DocumentType.CADERNETA_PREDIAL_RUSTICA]: '1+',
  [DocumentType.CADERNETA_PREDIAL_URBANA]: '0',
};

const RequiredDocumentsByTypeUrbana: RequiredDocuments = {
  [DocumentType.CERTIDAO_PERMANENTE]: '1',
  [DocumentType.CADERNETA_PREDIAL_URBANA]: '1',
  [DocumentType.CADERNETA_PREDIAL_RUSTICA]: '0+',
};

enum DocumentCheckStatus {
  OK = 'OK',
  TOO_FEW_DOCUMENTS = 'TOO_FEW_DOCUMENTS',
  TOO_MANY_DOCUMENTS = 'TOO_MANY_DOCUMENTS',
}

function checkRequiredDocumentsProvided(jobDocuments: JobDocument[]): DocumentCheckStatus {
  const isRustica = !jobDocuments.some(document => document.type === DocumentType.CADERNETA_PREDIAL_URBANA);
  const RequiredDocumentsByType = isRustica ? RequiredDocumentsByTypeRustica : RequiredDocumentsByTypeUrbana;

  for (const [doc, requirement] of Object.entries(RequiredDocumentsByType) as [DocumentType, RequirementType][]) {
    const count = jobDocuments.filter(jobDoc => jobDoc.type === doc).length;

    switch (requirement) {
      case '1':
        if (count < 1) return DocumentCheckStatus.TOO_FEW_DOCUMENTS;
        if (count > 1) return DocumentCheckStatus.TOO_MANY_DOCUMENTS;
        break;
      case '1+':
        if (count < 1) return DocumentCheckStatus.TOO_FEW_DOCUMENTS;
        break;
      case '0':
        if (count > 0) return DocumentCheckStatus.TOO_MANY_DOCUMENTS;
        break;
      case '0+':
        // No validation needed
        break;
    }
  }
  return DocumentCheckStatus.OK;
}

const checkCertidaoIsValid = (jobDocuments: JobDocument[]) =>
  !jobDocuments.find(
    item => item.status === DocumentStatus.UNACCEPTABLE || item.status === DocumentStatus.PROPERTY_ID_MISMATCH,
  );

export interface JobDetailsWidgetProps {
  job: Job;
  isLoadingSubmit: boolean;
  hideExtraData?: boolean;
  onUpdate: (name: string, description: string) => Promise<void>;
  onSubmit: (isGenerateReportPT: boolean, isGenerateReportEN: boolean) => Promise<void>;
  isErrorModalOpen?: boolean;
  onErrorModalClose?: () => void;
  errorMessage?: string;
}

export function JobDetailsWidget({
  job,
  isLoadingSubmit,
  hideExtraData = false,
  onUpdate,
  isErrorModalOpen,
  onSubmit,
  onErrorModalClose,
  errorMessage,
}: JobDetailsWidgetProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const brandStars = useColorModeValue('brand.500', 'white');
  const disabledHoverBgColor = useColorModeValue('secondaryGray.400', 'whiteAlpha.300');
  const disabledTextColor = useColorModeValue('navy.750', 'whiteAlpha.900');
  const disabledBgColor = useColorModeValue('secondaryGray.300', 'navy.700');
  const widgetBackgroundColor = useColorModeValue('white', 'navy.800');
  const cardBackgroundColor = useColorModeValue('_gray.200', 'navy.900');

  const [isGenerateReportEN, setIsGenerateReportEN] = useState(false);
  const [isGenerateReportPT, setIsGenerateReportPT] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);

  const jobDocuments = useTypedSelector(state => selectJobDocuments(state, job.id));
  const [documentModalError, setDocumentModalError] = React.useState<
    | DocumentsValidationErrorTypeEnum.RequiredDocumentsMissing
    | DocumentsValidationErrorTypeEnum.TooManyDocuments
    | DocumentsValidationErrorTypeEnum.CertidaoIsUnacceptable
    | null
  >(null);

  const { nameField, descriptionField, setName, setDescription, validateForm } = useJobForm(job.name, job.description);

  const debouncedName = useDebounce(nameField, { delay: 300 });
  const debouncedDescription = useDebounce(descriptionField, { delay: 300 });

  useEffect(() => {
    const initialName = job.name ?? '';
    if (initialName === debouncedName.value) {
      return;
    }

    onUpdate(debouncedName.value, descriptionField.value);
  }, [debouncedName]);

  useEffect(() => {
    const initialDescription = job.description ?? '';
    if (initialDescription === debouncedDescription.value) {
      return;
    }

    onUpdate(nameField.value, debouncedDescription.value);
  }, [debouncedDescription]);

  const handleSubmit = async () => {
    if (!checkCertidaoIsValid(jobDocuments)) {
      setDocumentModalError(DocumentsValidationErrorTypeEnum.CertidaoIsUnacceptable);
      return;
    }

    const requiredDocsValidityResult = checkRequiredDocumentsProvided(jobDocuments);

    if (requiredDocsValidityResult === DocumentCheckStatus.TOO_FEW_DOCUMENTS) {
      setDocumentModalError(DocumentsValidationErrorTypeEnum.RequiredDocumentsMissing);
      return;
    } else if (requiredDocsValidityResult === DocumentCheckStatus.TOO_MANY_DOCUMENTS) {
      setDocumentModalError(DocumentsValidationErrorTypeEnum.TooManyDocuments);
      return;
    }

    if (validateForm()) {
      await onSubmit(isGenerateReportPT, isGenerateReportEN);
    }
  };

  useDidUpdateEffect(() => {
    if (job.status === JobStatus['Job is completed'] && !job.problems.length && job.statusMetadata?.status === 'ok') {
      navigate(`/job-result/${job.id}`);
    }
  }, [job.status]);

  const [isJobErrorModalOpen, setIsJobErrorModalOpen] = useState(false);
  const [isProblemsNavigationModalOpen, setIsProblemsNavigationModalOpen] = useState(false);
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);

  const [prevJobStatus, setPrevJobStatus] = useState(job.status);
  useDidUpdateEffect(() => {
    if (prevJobStatus === JobStatus.Submitted && job.status !== JobStatus.Submitted) {
      if (job.problems?.length) {
        containerRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
        setIsProblemsNavigationModalOpen(true);
      }

      if (job.statusMetadata?.status !== 'ok') {
        setIsJobErrorModalOpen(true);
      }
    }

    setPrevJobStatus(job.status);
  }, [job, prevJobStatus, setPrevJobStatus, containerRef, setIsJobErrorModalOpen]);

  const ticketType = useTypedSelector(state => state.helpTools.selectedHelpTicketType);
  const isCollapsed = useTypedSelector(state => state.helpTools.isHelpTicketPanelCollapsed);
  const isOpen = !!ticketType;

  return (
    <>
      <Flex ref={containerRef} direction="column" gap="24px">
        <Card
          flexDirection="column"
          w="100%"
          p={0}
          bg={cardBackgroundColor}
          overflowX={{ sm: 'scroll', lg: 'hidden' }}
          position="static"
        >
          <WaitPleasePopup job={job} />
          <Flex direction="column" gap="24px">
            <JobMainInfoWidget
              job={job}
              hideExtraData={hideExtraData}
              nameField={nameField}
              descriptionField={descriptionField}
              onNameUpdate={setName}
              onDescriptionUpdate={setDescription}
            />

            <JobProblemsAccordion
              index={isAccordionExpanded ? 0 : undefined}
              jobId={job.id}
              onIndexChange={() => setIsAccordionExpanded(false)}
            />

            <Flex direction="column" gap="24px" p={'24px'} borderRadius={'20px'} bg={widgetBackgroundColor}>
              <JobDocumentsWidget jobId={job.id} />

              <hr />

              <Flex
                gap="24px"
                justifyContent="space-between"
                alignItems={'center'}
                direction={{ base: 'column', md: 'row' }}
              >
                <div className={'hidden'}>
                  <Text mb={'1'}>{t('pages.details.reportLanguages')}:</Text>
                  <Flex gap="24px" justifyContent="start" direction={{ base: 'column', md: 'row' }}>
                    <SwitchInputField
                      isChecked={isGenerateReportPT}
                      isDisabled={true}
                      label={'PT'}
                      onCheck={() => setIsGenerateReportPT(!isGenerateReportPT)}
                    />
                    <SwitchInputField
                      isChecked={isGenerateReportEN}
                      isDisabled={false}
                      label={'EN'}
                      onCheck={() => setIsGenerateReportEN(!isGenerateReportEN)}
                    />
                  </Flex>
                </div>
                <Flex gap="24px" justifyContent="end" direction={{ base: 'column', md: 'row' }}>
                  <ButtonCustom
                    color={disabledTextColor}
                    bgColor={disabledBgColor}
                    _hover={{ bgColor: disabledHoverBgColor }}
                    onClick={() => navigate(-1)}
                  >
                    {t('pages.details.cancel')}
                  </ButtonCustom>
                  <ButtonCustom onClick={handleSubmit} isLoading={isLoadingSubmit}>
                    {t('pages.details.submit')}
                  </ButtonCustom>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Card>
      </Flex>

      <ModalDocumentsValidationError
        jobId={job?.id}
        errorType={documentModalError}
        onClose={() => setDocumentModalError(null)}
      />

      {job.statusMetadata?.traceId && (
        <JobErrorModal
          isOpen={isJobErrorModalOpen}
          onClose={() => setIsJobErrorModalOpen(false)}
          traceId={job.statusMetadata?.traceId}
        />
      )}

      <ProblemsNavigationModal
        jobId={job.id}
        isOpen={isProblemsNavigationModalOpen}
        onClose={() => setIsProblemsNavigationModalOpen(false)}
        onResolveProblems={() => {
          setIsProblemsNavigationModalOpen(false);
          setIsAccordionExpanded(true);
        }}
      />
      {isErrorModalOpen && onErrorModalClose && errorMessage && (
        <ErrorModal isOpen={isErrorModalOpen} onClose={onErrorModalClose} error={errorMessage} />
      )}
    </>
  );
}
