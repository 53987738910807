import { Button, Flex, useColorModeValue, Text, useToast, useBreakpointValue } from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuDownload } from 'react-icons/lu';
import {
  EntityType,
  SigningDocumentType,
  SubmissionSigningStatus,
  UniversalDocumentStatus,
  useCreateUniversalDocumentMutation,
  useDeleteUniversalDocumentMutation,
  useReorderUniversalDocumentMutation,
  useUploadUniversalDocumentMutation,
} from '../../../../api';
import { SigningSingleResponse } from '../../../../api/signing';
import { FileCard } from '../../../../components/card/FileCard';
import { DocumentsViewer } from '../../../../components/documentsViewer/DocumentsViewer';
import { DocumentOutFilledIcon, ReloadIcon } from '../../../../components/icons/Icons';
import SortableList from '../../../../components/SortableList/SortableList';
import { Download, RecycleBing } from '../../../../libs/ui/atoms/src';
import { VCDropzone, VCMimeTypes } from '../../../../libs/ui/atoms/src/lib/dropzone/Dropzone';

const documentTypeKeys: Record<SigningDocumentType, string> = {
  [SigningDocumentType.DECLARATION]: 'certificate',
  [SigningDocumentType.INPUT]: '',
  [SigningDocumentType.PREPARED]: 'pages.signing.signaturePackStatusPendingSignatures',
  [SigningDocumentType.SIGNED]: 'pages.signing.signaturePackStatusSigned',
};

export interface SigningDetailsDocumentsWidgetProps {
  signing: SigningSingleResponse;
  onDocumentsChanged: () => void;
}

export const SigningDetailsDocumentsWidget = ({ signing, onDocumentsChanged }: SigningDetailsDocumentsWidgetProps) => {
  const { t } = useTranslation();
  const toast = useToast();

  const widgetBackgroundColor = useColorModeValue('white', 'navy.800');
  const buttonColor = useColorModeValue('brand.500', 'brand.500');
  const iconColor = useColorModeValue('navy.750', 'white');
  const cardColumns = useBreakpointValue({ base: 1, xxl: 2 });

  const [createDocument] = useCreateUniversalDocumentMutation();
  const [uploadDocument] = useUploadUniversalDocumentMutation();
  const [deleteDocument] = useDeleteUniversalDocumentMutation();
  const [reorderDocuments] = useReorderUniversalDocumentMutation();

  const handleDrop = useCallback(
    async (droppedFiles: File[]) => {
      try {
        await Promise.all(
          droppedFiles.map(async file => {
            const document = await createDocument({
              name: file.name,
              entityType: EntityType.SIGNING,
              entityId: signing.id,
            }).unwrap();

            const formData = new FormData();
            formData.set('inputFile', file);

            return uploadDocument({ id: document.id, formData }).unwrap();
          }),
        );
        onDocumentsChanged();
      } catch (error) {
        toast({
          title: t('error'),
          description: t('error'),
          status: 'error',
          duration: 1500,
        });
      }
    },
    [onDocumentsChanged, createDocument, uploadDocument, toast, t, signing],
  );

  const reorder = useCallback(
    async (orderedIds: string[]) => {
      const newItems = await reorderDocuments({ orderedIds }).unwrap();
      await new Promise(resolve => setTimeout(resolve, 1000));
      onDocumentsChanged();
      return newItems.map(item => item.id);
    },
    [onDocumentsChanged, reorderDocuments],
  );

  const handleDelete = useCallback(
    async (id: string) => {
      try {
        await deleteDocument({ id }).unwrap();
      } catch (error) {
        toast({ title: t('error'), description: t('error'), status: 'error', duration: 1500 });
      }
      onDocumentsChanged();
    },
    [deleteDocument, onDocumentsChanged, t, toast],
  );

  const documents = useMemo(() => {
    return [...(signing.documents ?? [])]
      .sort((a, b) => a.metadata?.orderIndex ?? 0 - (b?.metadata?.orderIndex ?? 0))
      .map((doc, idx) => ({
        orderIndex: doc.metadata?.orderIndex ?? idx + 1,
        id: doc.id,
        title: doc.name,
        iconChildren:
          doc.status === UniversalDocumentStatus.CREATED ? (
            <ReloadIcon />
          ) : (
            <DocumentOutFilledIcon width="24px" height="24px" />
          ),
        rightAction: (
          <Flex gap={'12px'}>
            <Button
              as="a"
              size={'xs'}
              p="0"
              cursor={'pointer'}
              color={'blue.500'}
              variant="ghost"
              href={doc.url}
              download={doc.name}
              target="_blank"
            >
              <LuDownload size={16} />
            </Button>

            {signing?.submission?.status !== SubmissionSigningStatus.PREPARING && (
              <Button
                size="xs"
                variant="ghost"
                color={'red.500'}
                p="0"
                cursor={'pointer'}
                borderColor={'red.500'}
                onPointerDown={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleDelete(doc.id);
                }}
              >
                <RecycleBing boxSize={5} color={'red'} />
              </Button>
            )}
          </Flex>
        ),
      }));
  }, [handleDelete, signing.documents, signing?.submission?.status]);

  return (
    <>
      {!signing.submission && (
        <Flex p={`24px 24px 0px 24px`} direction="column" bg={widgetBackgroundColor}>
          <VCDropzone
            title={t('pages.signing.uploadFiles')}
            onDrop={handleDrop}
            allowedFileTypes={[VCMimeTypes.PDF]}
            dropzoneFlexProps={{ p: '16px 32px', borderRadius: '16px' }}
          />
        </Flex>
      )}

      {!signing.submission || signing?.submission?.status === SubmissionSigningStatus.PREPARING ? (
        <SortableList
          items={documents}
          onMove={reorder}
          isDisabled={signing?.submission?.status === SubmissionSigningStatus.PREPARING}
        />
      ) : (
        <DocumentsViewer
          disableScale
          compactView
          gridProps={{ columns: cardColumns, padding: '24px' }}
          documentCards={signing.documents.map((doc, i) => (
            <FileCard
              key={i}
              title={doc.name}
              icon={<DocumentOutFilledIcon width="24px" height="24px" color={iconColor} />}
              footer={
                <Flex align="center" justify="space-between" gap="8px">
                  <Text
                    fontSize={{ base: 'xs', md: 'sm' }}
                    fontStyle="normal"
                    fontWeight="500"
                    lineHeight="normal"
                    color={iconColor}
                  >
                    {doc.metadata?.signingDocumentType
                      ? t(documentTypeKeys[doc.metadata?.signingDocumentType])
                      : t('documents')}
                  </Text>

                  <Button
                    as="a"
                    fontSize={{ base: 'xs', md: 'sm' }}
                    borderRadius={'10px'}
                    bg={'blue.500'}
                    href={doc.url}
                    download={doc.name}
                    _hover={{ bg: 'blue.600' }}
                    target="_blank"
                    rel="noopener noreferrer"
                    color={'white'}
                    leftIcon={<Download boxSize={4} />}
                  >
                    {t('download')}
                  </Button>
                </Flex>
              }
              cardProps={{ borderRadius: '15px' }}
            />
          ))}
        />
      )}
    </>
  );
};
