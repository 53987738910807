import { api } from './api';
import { defaultSerializeQueryArgs } from '@reduxjs/toolkit/query';
import { itemUpsertManyReducer } from '../store/list';
import { JobDocument } from './job-documents.private';
import { Template } from './template';
import { Signing } from './signing';
import { User } from './user.private';

export interface JobResponse {
  data?: Job[] | null;
  totalCount: number;
  pageCount: number;
}

export interface JobStatusMetadata {
  status: string;
  traceId: string;
}

export interface Job {
  id: string;
  publicId?: string | null;
  name: string;
  description: string;
  isPublished: boolean;
  organizationId: string;
  certidaoIdentifier?: string;
  statusMetadata?: JobStatusMetadata;
  status: JobStatus;

  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  publishedAt?: string | null;
  publishedBy?: string | null;

  problems: JobProblem[];
  templates?: Template[];
  contractTexts: JobMetadataContractText[];
  signing?: Signing;
  createdByUser: User;
}

export enum JobStatus {
  'Draft' = 'Draft',
  'Submitted' = 'Submitted',
  'Error occurred' = 'Error occurred',
  'Problems detected' = 'Problems detected',
  'Job is completed' = 'Job is completed',
  'job is archived' = 'job is archived',
}

export interface JobMetadataContractText {
  labelEn: string;
  labelPt: string;
  contractTextEn: string;
  contractTextPt: string;
}

export interface JobWithDocumentsAndResult {
  id: string;
  name: string;
  publicId?: string | null;
  description: string;
  status: JobStatus;
  organizationId: string;
  statusMetadata?: JobStatusMetadata;
  isPublished: boolean;
  filesArchiveUrl: string;

  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
  publishedAt?: string | null;
  publishedBy?: string | null;

  documents?: JobDocument[] | null;
  jobResult: JobResult;
  jobResults: JobResult[];
  problems: JobProblem[];
  contractTexts: JobMetadataContractText[];
  signing?: Signing;
  createdByUser: User;
}

export interface JobProblem {
  id: string;
  jobId: string;
  errorId: string;
  textEn: string;
  textPt: string;
  createdAt: string;
}

export interface JobResult {
  id: string;
  name: string;
  url: string;
  status: string;
  jobResultFiles?: JobResultDocument[] | null;
  createdAt: string;
}

export interface JobResultDocument {
  id: string;
  name: string;
  url: string;
  createdAt: string;
}

export interface JobCreateDto {
  name?: string | null;
  description?: string | null;
}

export interface JobUpdateDto {
  id: string;
  name?: string | null;
  description?: string | null;
  templateIds?: string[];
}

export interface JobGetListDto {
  skip?: number;
  take?: number;
  orderBy?: string;
  orderDirection?: string;
  search?: string;
  status?: JobStatus;
  lastUpdatedAt?: Date;
}

const jobPrivateApi = api.injectEndpoints({
  endpoints: build => ({
    getJobs: build.query<JobResponse, JobGetListDto>({
      query: ({ skip, take, orderBy, orderDirection, search, status, lastUpdatedAt }) => ({
        url: '/job',
        params: { skip, take, orderBy, orderDirection, search, status, lastUpdatedAt },
      }),
      serializeQueryArgs: ({ queryArgs: { skip, take, ...queryArgs }, endpointName, endpointDefinition }) =>
        defaultSerializeQueryArgs({
          queryArgs,
          endpointName,
          endpointDefinition,
        }),
      merge: (currentCacheData, responseData) => {
        if (currentCacheData.data && responseData.data) {
          return {
            ...currentCacheData,
            ...responseData,
            data: itemUpsertManyReducer(currentCacheData.data, {
              items: responseData.data,
              addAtEnd: true,
              replaceOldWithNew: true,
            }),
          };
        }
        return responseData;
      },
    }),
    getJobsList: build.query<JobResponse, JobGetListDto>({
      query: ({ skip, take, orderBy, orderDirection, search, status, lastUpdatedAt }) => ({
        url: '/job',
        params: { skip, take, orderBy, orderDirection, search, status, lastUpdatedAt },
      }),
      serializeQueryArgs: ({ queryArgs: { skip, take, ...queryArgs }, endpointName, endpointDefinition }) =>
        defaultSerializeQueryArgs({
          queryArgs,
          endpointName,
          endpointDefinition,
        }),
    }),
    getJob: build.query<JobWithDocumentsAndResult, { jobId: string }>({
      query: ({ jobId }) => ({ url: `/job/${jobId}` }),
    }),
    newJob: build.query<JobWithDocumentsAndResult, JobCreateDto>({
      query: dto => ({
        url: '/job',
        body: dto,
        method: 'POST',
      }),
    }),
    createJob: build.mutation<JobWithDocumentsAndResult, JobCreateDto>({
      query: dto => ({
        url: '/job',
        body: dto,
        method: 'POST',
      }),
    }),
    updateJob: build.mutation<JobWithDocumentsAndResult, JobUpdateDto>({
      query: dto => ({
        url: `/job`,
        body: dto,
        method: 'PUT',
      }),
    }),
    submitJob: build.mutation<
      JobWithDocumentsAndResult,
      { jobId: string; isGenerateReportPT?: boolean; isGenerateReportEN?: boolean }
    >({
      query: ({ jobId, isGenerateReportPT, isGenerateReportEN }) => ({
        url: `/job/${jobId}/submit`,
        method: 'POST',
        body: { isGenerateReportPT, isGenerateReportEN },
      }),
    }),
    submitContractsJob: build.mutation<JobWithDocumentsAndResult, { jobId: string }>({
      query: ({ jobId }) => ({
        url: `/job/${jobId}/submitContracts`,
        method: 'POST',
      }),
    }),
    publishJob: build.mutation<JobWithDocumentsAndResult, { jobId: string }>({
      query: ({ jobId }) => ({
        url: `/job/${jobId}/publish`,
        method: 'POST',
      }),
    }),
    unpublishJob: build.mutation<JobWithDocumentsAndResult, { jobId: string }>({
      query: ({ jobId }) => ({
        url: `/job/${jobId}/unpublish`,
        method: 'POST',
      }),
    }),
    deleteJob: build.mutation<JobWithDocumentsAndResult, { jobId: string }>({
      query: ({ jobId }) => ({
        url: `/job/${jobId}`,
        method: 'DELETE',
      }),
    }),
    archiveJob: build.mutation<JobWithDocumentsAndResult, { jobId: string }>({
      query: ({ jobId }) => ({
        url: `/job/${jobId}/archive`,
        method: 'POST',
      }),
    }),
    unarchiveJob: build.mutation<JobWithDocumentsAndResult, { jobId: string }>({
      query: ({ jobId }) => ({
        url: `/job/${jobId}/unarchive`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetJobsQuery: useGetJobsPrivateQuery,
  useLazyGetJobsQuery: useLazyGetJobsPrivateQuery,
  useGetJobQuery: useGetJobPrivateQuery,
  useLazyGetJobQuery: useLazyGetJobPrivateQuery,
  useGetJobsListQuery: useGetJobsListPrivateQuery,
  useLazyGetJobsListQuery: useLazyGetJobsListPrivateQuery,
  useNewJobQuery,
  useLazyNewJobQuery,
  useCreateJobMutation,
  useUpdateJobMutation,
  useDeleteJobMutation,
  useSubmitJobMutation,
  useSubmitContractsJobMutation,
  usePublishJobMutation,
  useUnpublishJobMutation,
  useArchiveJobMutation,
  useUnarchiveJobMutation,
  endpoints: JobPrivateEndpoints,
  util: JobPrivateApiUtil,
} = jobPrivateApi;
