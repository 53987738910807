import { Flex, Tooltip, useColorModeValue, useToast } from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { UniversalDocumentStatus } from '../../../../api';
import { SigningSingleResponse } from '../../../../api/signing';
import Card from '../../../../components/card/Card';
import { WaitPleasePopup } from '../../../../components/waitPleasePopup/WaitPleasePopup';
import { Button } from '../../../../libs/ui/atoms/src';
import { SignersWidget } from '../components/signers-widget';
import { SigningMainInfoWidget } from '../components/signing-main-info';
import { SigningDetailsDocumentsWidget } from './SigningDocumentsWidget';
import { useSigners } from '../signer/use-signers';

export interface SigningDetailsWidgetProps {
  isLoadingSubmit: boolean;
  name?: string;
  signing: SigningSingleResponse;
  onDocumentsChanged: () => void;
  onSubmit: () => void;
  onSingersCountChanged: () => void;
  onNameChanged: (value: string) => void;
}

export const SigningDetailsWidget = ({
  signing,
  name,
  onDocumentsChanged,
  onSubmit,
  onSingersCountChanged,
  isLoadingSubmit,
  onNameChanged,
}: SigningDetailsWidgetProps) => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();

  const [isNewSignerCreating, setIsNewSignerCreating] = useState(false);

  const { isLoading: isSignersLoading, signers, onCreate, onUpdate, onDelete } = useSigners({ signingId: signing.id });

  const isSignersValid = useMemo(() => {
    return signers?.every(signer => signer.isValid);
  }, [signers]);

  const isSubmitDisabled = useMemo(() => {
    return (
      !signers?.length ||
      !signing?.documents?.length ||
      !isSignersValid ||
      signing?.documents.some(d => d.status === UniversalDocumentStatus.CREATED)
    );
  }, [signers, signing.documents, isSignersValid]);

  const handleSubmit = useCallback(() => {
    if (isNewSignerCreating) {
      toast({ title: t('pages.signing.unsavedChanges'), status: 'warning', duration: 3000, isClosable: true });
      return;
    }

    onSubmit();
  }, [isNewSignerCreating, onSubmit, t, toast]);

  const cardBackgroundColor = useColorModeValue('_gray.200', 'navy.900');
  const widgetBackgroundColor = useColorModeValue('white', 'navy.800');
  const disabledHoverBgColor = useColorModeValue('secondaryGray.400', 'whiteAlpha.300');
  const disabledTextColor = useColorModeValue('navy.750', 'whiteAlpha.900');
  const disabledBgColor = useColorModeValue('secondaryGray.300', 'navy.700');

  return (
    <Flex direction="column" gap="24px" p="24px">
      <Card
        w="100%"
        p={0}
        bg={cardBackgroundColor}
        overflowX={{ sm: 'auto', lg: 'hidden' }}
        position="static"
        display={'flex'}
        flexDirection="column"
        gap={'24px'}
      >
        <SigningMainInfoWidget name={name} signing={signing} onNameUpdate={onNameChanged} />

        <Flex direction="column" borderRadius={'20px'} overflow="hidden" bg={widgetBackgroundColor}>
          <SigningDetailsDocumentsWidget signing={signing} onDocumentsChanged={onDocumentsChanged} />
        </Flex>

        <SignersWidget
          signing={signing}
          signers={signers}
          isLoading={isSignersLoading}
          onCreate={onCreate}
          onUpdate={onUpdate}
          onDelete={onDelete}
          isNewSignerCreating={isNewSignerCreating}
          onSignerCreateClick={() => setIsNewSignerCreating(true)}
          onSignerDeleted={() => {
            setIsNewSignerCreating(false);
            onSingersCountChanged();
          }}
          onSignerCreated={() => {
            setIsNewSignerCreating(false);
            onSingersCountChanged();
          }}
        />

        {!signing?.submission && (
          <Flex gap="24px" justifyContent="end" direction={{ base: 'column', md: 'row' }} position="relative">
            <Button
              color={disabledTextColor}
              bgColor={disabledBgColor}
              _hover={{ bgColor: disabledHoverBgColor }}
              onClick={() => navigate(-1)}
            >
              {t('pages.details.cancel')}
            </Button>
            <Tooltip shouldWrapChildren label={isSubmitDisabled ? t('pages.signing.submitDisabledTitle') : ''}>
              <Button onClick={handleSubmit} isDisabled={isSubmitDisabled} isLoading={isLoadingSubmit}>
                {t('pages.details.submit')}
              </Button>
            </Tooltip>
          </Flex>
        )}
      </Card>

      <WaitPleasePopup signing={signing} />
    </Flex>
  );
};
